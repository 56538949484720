.card-container {
    width: 300px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
    background: white;
    transition: transform 0.2s;
    margin-bottom: 35px;
}

.card-container:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover; /* Ensures images cover the area nicely */
}

.card-content {
    padding: 15px;
}

.card-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.card-text {
    color: #666;
    font-size: 14px;
}

.card-container a {
    text-decoration: none;
    color: #EF6191; /* Signature color for links */
}

.card-container a:hover {
    color: #D0527A; /* Slightly darker shade for hover state */
}

.card-container a:visited {
    color: #EF6191; /* Signature color for visited links */
}