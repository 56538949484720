.subbar-discover {
    background-color: #fff;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 800px;
}
.subbar-discover button,
.subbar-discover input,
.subbar-discover select {
    margin: 5px;
    font-size: 14px;
    height: 50px;
}
/* Container for the entire feed */
.feed-container-discover {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Arial, sans-serif;
}
/* Event Card Styling */
.event-card-discover {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 600px;
    margin-bottom: 20px;
    text-align: center; /* Center all text inside the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* Profile picture styling - Circular */
.profile-picture-discover img {
    width: 100px;
    height: 100px;
    border-radius: 50%; /* Make it a circle */
    object-fit: cover; /* Ensure the image fills the circle */
}
/* Flexbox for artist info to position picture on the left and text on the right */
.artist-info-discover {
    display: flex; /* Flexbox for alignment */
    align-items: center; /* Vertically center the image and text */
    justify-content: center; /* Horizontally center the entire content */
    text-align: left; /* Left-align the text within the container */
    margin-bottom: 20px;
}
.artist-info-discover h2 {
    font-size: 24px;
    margin: 0; /* Remove any default margin if needed */
}
.artist-info-discover h4 {
    font-size: 18px;
    color: #777;
}
/* Image carousel styling */
.image-carousel-discover img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
/* Event details */
.event-details-discover p {
    font-size: 16px;
    margin: 10px 0;
}
/* Date and Location Styling */
.event-details-discover p strong {
    font-weight: bold;
}
/* Buy Tickets button */
.buy-tickets-discover button {
    padding: 10px 20px;
    background-color: #EF6191;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
.buy-tickets-discover button:hover {
    background-color: #c45577;
}
/* Aligning text and elements centrally */
.no-results-discover {
    font-size: 18px;
    color: #555;
}