.messages-container {
    display: flex;
    height: calc(100vh - 12vh); /* Subtract border width */
    min-height: 500px; /* Minimum height to prevent too small containers */
    display: flex;
    height: calc(100vh - 12vh); /* Subtract border width */
    min-height: 500px; /* Minimum height to prevent too small containers */
    background-color: #fff;
    position: relative; /* Add this for proper child positioning */
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-track {
    all: unset !important;
}

.message-content {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    flex: 1;
    height: 100%; /* Take full height of parent */
    height: 100%; /* Take full height of parent */
    word-break: break-word;
    position: relative; /* For proper child positioning */
    position: relative; /* For proper child positioning */
}

.message-content button {
    margin: 8px 0;
}

.message-content .bg-white {
    margin: 8px 0;
}
.profiles-list {
    flex: 0 0 30%; /* Fixed width for the profiles list */
    border-right: 1px solid #ddd;
    background-color: #f9f9f9;
    overflow-y: auto; /* Make it scrollable */
}

.profile-item {
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f9f9f9;
}

.profile-item:hover {
    background-color: #f0f0f0;
}
.profile-item.selected {
    background-color: #f0f0f0;
}

.profile-item p {
    margin: 0;
    color: #333;
}

.profile-item span {
    font-size: 0.85em;
    color: #999;
}


.messages-list {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    background-color: #fff;
    /* Reserve space for the message input */
    padding-bottom: 10%; /* Adjust based on your message input height */
    /* Reserve space for the message input */
    padding-bottom: 10%; /* Adjust based on your message input height */
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Ensure the header has a background color to cover content when scrolling */
    z-index: 1000; /* Make sure it stays on top of other elements */
    border-bottom: 1px solid #ddd;
    vertical-align: center;
    margin-bottom: 8px;
}


.message-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    overflow-x: scroll;
}

.message-item.sent {
    align-items: flex-end;
}


.message-item-content {
    display: inline-block;
    padding: 12px 16px;
    border-radius: 12px;
    max-width: 70%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 1em;
    word-wrap: break-word;
}

.message-item-content.sent {
    background-color: #EF6191;
    color: white;
    text-align: left;
}

.message-item-content.received {
    background-color: #f1f1f1;
    color: #333;
    text-align: left; /* Align text inside the bubble */
}

/* Timestamp Styling */
.message-item-time {
    font-size: 0.85em;
    color: #999;
    margin-top: 4px; /* Add spacing between bubble and timestamp */
}


.message-item-time.sent {
    text-align: right; /* Align to the right for sent messages */
}

.message-item-time.received {
    text-align: left; /* Align to the left for received messages */
}

.message-item p {
    margin: 0;
    color: #333;
}

.message-input {
    position: absolute; /* Change to absolute positioning */
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute; /* Change to absolute positioning */
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    z-index: 10; /* Ensure it stays above messages */
}

.message-input input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    flex: 1;
    vertical-align: bottom;
}

.message-input button {
    padding: 10px;
    border: none;
    background-color: #EF6191;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
    width: 20%;
    flex-shrink: 0;
}


.message-input button:hover {
    background-color: #c45577;
}

.message-item button {
    background: #EF6191; /* Signature color */
    border: none;
    border-radius: 8px;
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 5px; /* Space between buttons */
    transition: background-color 0.3s;
}

.message-item button:hover {
    background: #c45577; /* Darker red on hover */
}

.message-item .deny-button {
    background: #EF6191; /* Light grey for deny button */
}

.message-item .deny-button:hover {
    background: #aaa; /* Darker grey on hover */
}

@media (max-width: 768px) {
    .message-input {
        display: flex;
        flex-direction: column; /* Stack input and button vertically */
        gap: 8px; /* Slightly larger gap for better touch accessibility */
        padding: 10px;
        box-sizing: border-box; /* Prevent overflow */
        width: 100%;
    }

    .message-input input {
        width: 100%; /* Full width for the input */
        padding: 12px; /* Slightly larger padding for better touch */
        border: 1px solid #ddd;
        border-radius: 8px;
        box-sizing: border-box;
        font-size: 16px; /* Larger font for readability */
    }

    .message-input button {
        width: 100%; /* Full width for the button */
        padding: 12px; /* Match the input padding for consistency */
        border: none;
        background-color: #EF6191;
        color: white;
        font-weight: bold;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.3s;
        font-size: 16px; /* Larger font for better readability */
    }

    .message-input button:active {
        background-color: #d54f73; /* Slightly darker color on press for feedback */
    }


    .message-input button:hover {
        background-color: #c45577;
    }

    .messages-container {
        height: calc(100vh - 60px); /* Account for mobile footer */
    }

    .messages-container {
        height: calc(100vh - 60px); /* Account for mobile footer */
    }

    .message-input {
        padding-bottom: calc(10px + env(safe-area-inset-bottom)); /* Account for notches/home indicators */
        padding-bottom: calc(10px + env(safe-area-inset-bottom)); /* Account for notches/home indicators */
    }
    .profiles-list {
        padding-bottom: 60px; /* Adjust to the height of the footer */
        box-sizing: border-box; /* Prevent overflow issues */
    }
}