.navbar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.nav-button {
    background: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.nav-button.active {
    background: #007bff;
    color: white;
}

.nav-button:hover {
    background: #0056b3;
    color: white;
}