.user-profile {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

/* Styles for the Slider component */
.slick-slider {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.slick-slide img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}

.slick-prev, .slick-next {
    z-index: 1;
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}

.slick-prev:before, .slick-next:before {
    font-size: 30px;
    opacity: 0.8;
}

.slick-dots {
    bottom: 10px;
}

.slick-dots li button:before {
    font-size: 12px;
    color: white;
    opacity: 0.8;
}

/* Rest of your existing styles */
.user-profile h2, .user-profile p {
    margin: 10px 0;
    color: #333;
}

.user-profile .edit-button {
    background: #EF6191;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.user-profile .edit-button:hover {
    background: #c45577;
}

.user-profile .details {
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
}

.user-profile .details label {
    font-weight: bold;
    margin-right: 10px;
}

.user-profile input, .user-profile textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 5px;
}

.user-profile img {
    width: 100%;
    height: auto;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    margin-top: 20px;
}
