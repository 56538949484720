header {
    background-color: white; /* White background color */
    color: #EF6191; 
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2); /* Increased and more diffused shadow */
    border-bottom: 2px solid #ccc;
    font-family:"'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif";
    flex-wrap: wrap;
}

.header-content  {
    display: flex;
    
    align-items: center;
}

.header-icon {
    width: 35px;  /* Adjust size as needed */
    height: 35px;
    margin-right: 10px;
}

header h1 {
    margin: 0;
    font-size: 30px; /* Reduced size for better fit on phones */
    white-space: nowrap;
}

nav {
    display: flex;
    align-items: center;
}

nav a {
    color: #EF6191;
    text-decoration: none;
    margin-left: 20px;
    font-weight: 500;
    font-size: 16px;
}

nav a:hover {
    text-decoration: underline;
}

.sub-header nav a {
    color: grey;
    text-decoration: none;
    margin-right: 15px;
    transition: color 0.3s ease;
}

.sub-header nav a:hover {
    color: black;
}

.sub-header {
    background-color: white;
    padding: 10px 0;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2);
    margin-bottom: 20px;
}

.sub-header nav {
    display: inline-block;
    
}

.sub-header nav a {
    padding: 5px 10px;
    font-weight: bold;
}

.sub-header nav a.active {
    color: black;
    border-bottom: 2px solid black;
}

@media (max-width: 768px) {
    header {
        padding: 10px;
        flex-direction: column; /* Stack header elements */
        align-items: center; /* Center content */
        max-width: 100%;
    }

    .header-content {
        justify-content: center;
        margin-bottom: 10px;
    }

    nav {
        justify-content: center;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 10px;
    }

    header h1 {
        font-size: 30px; /* Smaller font size for phones */
        text-align: center;
    }

    .header-icon {
        width: 30px;
        height: 30px;
    }

    nav a {
        font-size: 18px; /* Smaller links for phones */
        margin: 0 5px;
    }
}