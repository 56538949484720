.feed-container {
    width: 100%;
    position: relative;
    overflow: visible;

}

.feed-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.05), transparent);
    animation: backgroundMove 10s linear infinite;
    z-index: -1;
}
@keyframes ambientShift {
    0% {
        opacity: 0.5;
        transform: scale(1);
    }
    100% {
        opacity: 0.8;
        transform: scale(1.1);
    }
}
.event-details{
    color:#fff
}


.event-card:hover {
    transform: translateY(-10px);
    box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.4),
        0 0 0 1px rgba(255, 255, 255, 0.1),
        0 0 20px rgba(239, 97, 145, 0.2);
}

.feed-carousel {
    width: 115%;
    max-width: 110%; /* Changed from 90% to use full width */
    perspective: 1200px;
    position: relative;
    align-items: center;
    padding: 20px 0;
    display: flex;
    text-align: center;
    z-index: 1;
    justify-content: center;
    margin: 0 auto;
    min-height: 520px;
    margin-bottom: 50px;
}
.feed-carousel .slick-slide {
    transform: scale(0.85) translateZ(-100px);
    opacity: 0.6;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    filter: blur(2px);
}
.feed-carousel .slick-center {
    transform: scale(1) translateZ(0);
    opacity: 1;
    filter: blur(0);
}


.feed-carousel .slick-prev-slide {
    transform: scale(0.85) translateX(-50%) rotateY(10deg) translateZ(0px); /* Move way left and closer */
}

.feed-carousel .slick-next-slide {
    transform: scale(0.85) translateX(50%) rotateY(-10deg) translateZ(0px); /* Move way right and closer */
}
slick-track {
    transform: translate3d(0px, 0px, 0px) !important;  /* Half of current value */
						
}

.carousel-slide {
    transform-style: preserve-3d;
    perspective: 1000px;
}


/* Card styling */
.event-card {
    width: 320px;
    height: 520px;
    background: rgba(28, 32, 40, 0.8);
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    transform-style: preserve-3d;
    box-shadow: 
        0 8px 32px rgba(0, 0, 0, 0.3),
        0 0 0 1px rgba(255, 255, 255, 0.05);
}

.profile-picture {
    height: 65%;
    overflow: hidden;
    
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;

}
.event-card:hover .profile-picture img {
    transform: scale(1.05);
}
.artist-info {
    padding: 1.5rem;
    background: linear-gradient(
        to bottom,
        rgba(28, 32, 40, 0.8) 0%,
        rgba(28, 32, 40, 0.95) 100%
    );
    position: relative;
}

.artist-info h2 {
    color: #ffffff;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.feed-carousel .slick-slider {
    width: 65%;
    max-width: 100%; /* Ensure full width for feed carousel */
    margin: 0 auto;
}

.artist-info h4 {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1rem;
    margin: 0.5rem 0 0;
    font-weight: 400;
}

/* Center slide styling */
.slick-center .event-card {
    transform: scale(1.1) translateY();
    overflow: visible;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.9);
    z-index: 2;

}

/* Side slides */
.slick-slide:not(.slick-center) .event-card {
    transform: scale(0.8) translateX(0) rotateY(10deg);
    opacity: 0.6;
}

.slick-slide.slick-prev-slide .event-card {
    transform: scale(0.8) translateX(5%) rotateY(-10deg);
}

.slick-slide.slick-next-slide .event-card {
    transform: scale(0.8) translateX(-5%) rotateY(10deg);
}

/* Navigation styling */
.slick-arrow {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 10;
    transition: all 0.3s ease;
}

.slick-arrow:hover {
    background: rgba(239, 97, 145, 0.2);
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(239, 97, 145, 0.3);
}

.slick-prev {
    left: 5%;
}

.slick-next {
    right: 5%;
}

/* Dots styling */
.slick-dots {
    bottom: -50px !important; /* Move dots further down */
    display: flex !important;
    justify-content: center;
    gap: 0.5rem;
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    z-index: 1; /* Lower z-index than the cards */
}

.slick-dots li {
    margin: 0;
}

.slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
}

.slick-dots li.slick-active button {
    width: 24px;
    background: #EF6191;
}

.buy-tickets button {
    background: linear-gradient(90deg, #EF6191, #2962ff);
    border: none;
    border-radius: 12px;
    color: white;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 12px rgba(239, 97, 145, 0.3);
}

.buy-tickets button:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(239, 97, 145, 0.4);
}

@keyframes neonFlicker {
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
        text-shadow:
            0 0 4px #fff,
            0 0 10px #EF6191,
            0 0 20px #EF6191,
            0 0 30px #EF6191,
            0 0 40px #EF6191,
            0 0 50px #EF6191,
            0 0 60px #EF6191,
            0 0 70px #EF6191;
    }
    20%, 24%, 55% {
        text-shadow: none;
    }
}

.artist-info h2 {
    /* Remove the animation property to stop continuous flickering */
    /* animation: neonFlicker 1.5s infinite alternate; */
}

/* Apply the neon flicker effect on hover */
.event-card:hover .artist-info h2 {
    animation: neonFlicker 10s infinite alternate;
}

/* Neon Flicker Effect */
@keyframes neonFlicker {
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
        text-shadow:
            0 0 4px #fff,
            0 0 10px #EF6191,
            0 0 20px #EF6191,
            0 0 30px #EF6191,
            0 0 40px #EF6191,
            0 0 50px #EF6191,
            0 0 60px #EF6191,
            0 0 70px #EF6191;
    }
    20%, 24%, 55% {
        text-shadow: none;
    }
}