.category-preview {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    margin-bottom: 20px;
    scroll-snap-type: x mandatory; 
    -webkit-overflow-scrolling: touch; /* For iOS WebKit browsers */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; 
}


.card-container {
    flex: 0 0 250px; /* Each card will take up 250px of space in the flex container */
    width: 250px; /* Correct width for cards, ensuring they are wide enough */
    margin-right: 15px;
    scroll-snap-align: start; /* Ensures the scrolling snaps to the start of each card */
}

.title {
    font-family: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif"; /* You can choose any font you like */
    font-size: 20px; /* Example size */
    font-weight: bold; /* Example weight */
    color: #EF6191; /* Example color */
    text-align: center;
  }

@media (max-width: 768px) {
    .card-container {
        width: 90%; /* Makes the cards take more width on smaller screens */
        margin-right: 10px; /* Smaller margin on smaller screens */
    }
}

.category-preview::-webkit-scrollbar {
    height: 8px; /* Height of the horizontal scrollbar */
}

.category-preview::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
}

.category-preview::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar itself */
}

.category-preview::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovered */
}

/* For Firefox */
.category-preview {
    scrollbar-width: thin; /* Can use 'auto' or 'thin' */
    scrollbar-color: #888 #f1f1f1; /* Scrollbar color and track color */
}