.home-container {
    position: relative;
    background: 
        radial-gradient(circle at 20% 30%, rgba(239, 97, 145, 0.15) 0%, transparent 50%),
        radial-gradient(circle at 80% 70%, rgba(41, 98, 255, 0.15) 0%, transparent 50%);
    min-height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.home-container::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at center, rgba(255, 255, 255, 0.05), transparent 70%);
    animation: rotateBG 30s linear infinite;
    z-index: -1;
}


@keyframes rotateBG {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

/* Adjust font sizes */
h2 {
    font-weight: 700;
    font-size: 2rem;
}

h4 {
    font-weight: 500;
    font-size: 1.2rem;
}

p {
    font-weight: 300;
}