.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-header h2 {
    font-size: 24px;
    color: #333;
}

.modal-close {
    background: none;
    border: none;
    font-size: 24px;
    color: #EF6191;
    cursor: pointer;
}

.address-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-bottom: 20px;
}

.address-fields input {
    width: 100%;
    height: 48px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    padding: 10px 20px;
    transition: border-color 0.3s;
}

.address-fields input:focus {
    border-color: #EF6191;
    outline: none;
}

.address-fields input[name="street"] {
    grid-column: 1 / -1;
}

.map-container {
    height: 300px;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
}

.address-button button {
    display: block;
    width: 100%;
    height: 50px;
    background: #EF6191;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 15px;
}

.address-button button:hover {
    background: #c45577;
}

.address-button button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.error {
    color: #EF6191;
    margin-bottom: 15px;
    font-size: 14px;
}