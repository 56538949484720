@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
    margin: 0;
    font-family: "Poppins", sans-serif;
    background: #f7f7f7;
}

.forgot-password-link {
    text-align: center;
    margin-top: 15px;
}

.forgot-password-link a {
    color: #EF6191;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s;
}

.forgot-password-link a:hover {
    color: #c45577;
}

.wrapper {
    width: 360px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 40px;
    margin: auto;
    margin-top: 50px;
}

.wrapper h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.wrapper .input-box {
    position: relative;
    margin-bottom: 20px;
}

.wrapper .input-box input,
.wrapper .input-box select,
.wrapper .input-box textarea {
    width: 100%;
    height: 48px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    padding: 10px 20px;
    transition: border-color 0.3s;
}

.wrapper .input-box textarea {
    height: 100px;
    resize: vertical;
}

.wrapper .input-box input:focus,
.wrapper .input-box select:focus,
.wrapper .input-box textarea:focus {
    border-color: #EF6191;
}

.wrapper .btn {
    display: block;
    width: 100%;
    height: 50px;
    background: #EF6191;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.wrapper .btn:hover {
    background: #c45577;
}

/* Improved Dropdown Checkbox Styles */
.dropdown-checkbox {
    position: relative;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 16px;
    color: #333;
}

.dropdown-header:hover {
    border-color: #EF6191;
    background-color: #fcf2f5;
}

.arrow {
    border: solid #EF6191;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    transition: transform 0.3s;
}

.arrow.open {
    transform: rotate(-135deg);
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #EF6191;
    border-top: none;
    border-radius: 0 0 8px 8px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(239, 97, 145, 0.1);
}

.checkbox-item {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    transition: background-color 0.2s;
}

.checkbox-item:hover {
    background-color: #fcf2f5;
}

.checkbox-item input[type="checkbox"] {
    margin-right: 12px;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #EF6191;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.checkbox-item input[type="checkbox"]:checked {
    background-color: #EF6191;
}

.checkbox-item input[type="checkbox"]:checked::after {
    content: '\2714';
    font-size: 14px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.checkbox-item label {
    cursor: pointer;
    font-size: 14px;
    color: #333;
}

/* File input styling */
.wrapper .input-box input[type="file"] {
    padding: 10px;
    font-size: 14px;
}

.wrapper .input-box input[type="file"]::file-selector-button {
    background-color: #EF6191;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.wrapper .input-box input[type="file"]::file-selector-button:hover {
    background-color: #c45577;
}
.capacity-slider {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.capacity-slider label {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
}

.capacity-slider input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.capacity-slider input[type="range"]:hover {
    opacity: 1;
}

.capacity-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #EF6191;
    cursor: pointer;
}

.capacity-slider input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #EF6191;
    cursor: pointer;
    border: none;
}
