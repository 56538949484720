.input-box {
    position: relative;
    margin-bottom: 15px;
}

.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.info-icon {
    font-size: 18px;
    color: #EF6191;
}