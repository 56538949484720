.bg-gradient-to-b {
    background: linear-gradient(180deg, rgba(239, 97, 145, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  }
  
  /* Input Fields */
  .MuiOutlinedInput-root {
    transition: all 0.2s ease-in-out;
  }
  
  .MuiOutlinedInput-root:hover {
    transform: translateY(-1px);
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
  }
  
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #EF6191 !important;
    border-width: 2px !important;
  }
  
  /* Labels */
  .MuiInputLabel-root {
    font-weight: 500;
  }
  
  .MuiInputLabel-root.Mui-focused {
    color: #EF6191 !important;
  }
  
  /* Select Dropdowns */
  .MuiSelect-select {
    padding: 12px !important;
  }
  
  /* Chips */
  .MuiChip-root {
    transition: all 0.2s ease-in-out;
    height: 32px !important;
  }
  
  .MuiChip-root:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(239, 97, 145, 0.2);
  }
  
  .MuiChip-label {
    font-weight: 500 !important;
    padding: 0 12px !important;
  }
  
  /* Form Container */
  .venue-booking-form {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Date/Time Pickers */
  .MuiPickersDay-root.Mui-selected {
    background-color: #EF6191 !important;
  }
  
  .MuiClock-pin {
    background-color: #EF6191 !important;
  }
  
  .MuiClockPointer-root {
    background-color: #EF6191 !important;
  }
  
  .MuiClockPointer-thumb {
    border-color: #EF6191 !important;
    background-color: #EF6191 !important;
  }
  
  /* Checkboxes */
  .MuiCheckbox-root.Mui-checked {
    color: #EF6191 !important;
  }
  
  /* Form Groups */
  .form-group {
    margin-bottom: 24px;
  }
  
  /* Paper Container */
  .MuiPaper-root {
    transition: transform 0.2s ease-in-out;
  }
  
  .MuiPaper-root:hover {
    transform: translateY(-2px);
  }
  
  /* Success Alert */
  .MuiAlert-standardSuccess {
    background-color: #EF6191 !important;
    color: white !important;
  }
  
  .MuiAlert-standardSuccess .MuiAlert-icon {
    color: white !important;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 600px) {
    .MuiTextField-root {
      margin-bottom: 16px;
    }
    
    .MuiButton-root {
      height: 48px !important;
    }
    
    .MuiChip-root {
      height: 28px !important;
    }
  }
  
  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    /* background: rgba(239, 97, 145, 0.1);*/
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(239, 97, 145, 0.3);
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(239, 97, 145, 0.5);
  }
  
  /* Focus States */
  .MuiOutlinedInput-root:focus-within {
    box-shadow: 0 0 0 2px rgba(239, 97, 145, 0.2);
  }
  
  /* Disabled States */
  .Mui-disabled {
    background-color: rgba(0, 0, 0, 0.02) !important;
  }
  
  /* Button States */
  .MuiButton-root:active {
    transform: translateY(1px);
  }
  
  /* Select Dropdown Menu */
  .MuiMenu-paper {
    margin-top: 8px;
    border-radius: 12px !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  }
  
  .MuiMenuItem-root {
    padding: 12px 16px !important;
  }
  
  /* Input Autofill Style */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: inherit;
    transition: background-color 5000s ease-in-out 0s;
  }
  
  /* Error States */
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #ff4444 !important;
  }
  
  .Mui-error.MuiFormLabel-root {
    color: #ff4444 !important;
  }
  
  /* Helper Text */
  .MuiFormHelperText-root {
    margin-top: 4px !important;
    font-size: 0.75rem !important;
  }
  
  /* Loading States */
  .MuiCircularProgress-root {
    color: #EF6191 !important;
  }