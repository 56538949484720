footer {
    background-color: #F7F7F7;
    color: #484848;
    text-align: center;
    padding: 1px 0;
    font-size: 14px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000; /* Ensure the footer is above other content */
}

.main-content {
    padding-bottom: 50px; /* Add padding to the bottom of the main content */
}

footer p {
    margin: 0;
}

.footer-section {
    padding: 0; /* Remove padding inside sections */
}

Typography, Link {
    margin: 0; /* Remove default margins */
    font-size: 0.75rem; /* Ensure small font sizes */
}