.user-profile .date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 50px;
}

.available {
    background-color: green !important;
}

.interest-shown {
    background-color: yellow !important;
}

.booked {
    background-color: red !important;
}

.user-profile .date-range-picker {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align items */
    margin-bottom: 10px;
}

.user-profile .remove-date-range {
    white-space: nowrap; /* Prevents the button from breaking into multiple lines */
}

.user-profile .date-entry {
    display: flex;
    align-items: center; /* Adjust this to 'baseline' if you prefer */
    justify-content: space-between; /* This spreads out the text and the button */
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Optional: adds a separator between entries */
}

.user-profile .date-details {
    flex-grow: 1; /* Allows the date details to take up the remaining space */
    margin-right: 20px; /* Adds some space between the text and the button */
}

.user-profile .react-datepicker-wrapper {
    width: auto; /* Change this if it's making the input field full width */
}

.user-profile .react-datepicker__input-container input {
    width: 100%; /* Ensures the input field inside the date picker matches other forms */
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 5px;
}

.react-datepicker__day--highlighted.available {
    background-color: green;
    color: white;
}

.react-datepicker__day--highlighted.interest-shown {
    background-color: yellow;
    color: black;
}

.react-datepicker__day--highlighted.booked {
    background-color: red;
    color: white;
}

.user-profile button {
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px; /* Ensure buttons have adequate spacing */
}
.user-profile button:hover {
    background: #c45577;
}

.user-profile .react-datepicker__navigation { 
    width: 20px; /* Ensure the width is appropriate */
    height: 20px; /* Ensure the height is appropriate */
    border-width: 6px 6px 0 0; /* Adjust if needed to get the right arrow size */
}

.user-profile .react-datepicker__navigation--previous {
    left: 10px;
}

.user-profile .react-datepicker__navigation--next {
    right: 10px;
}