.geosuggest {
    position: relative;
    width: 100%;
}

.geosuggest__input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ddd;
    background: #fff;
    z-index: 5;
}

.geosuggest__suggests--hidden {
    display: none;
}

.geosuggest__item {
    padding: 8px;
    cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item--active {
    background: #f0f0f0;
}