.venue-popup-link {
    color: #EF6191;
    text-decoration: none;
    cursor: pointer;
}

.venue-popup-link:hover {
    text-decoration: underline;
}
.leaflet-popup-content h1 {
    color: #EF6191;
}

.leaflet-popup-content .venue-popup-link {
    color: #EF6191;
    text-decoration: none;
}

.leaflet-popup-content .venue-popup-link:hover {
    text-decoration: underline;
    color: #c45577;
}