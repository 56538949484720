.booking-page {
    width: 100%; /* Full width of the container */
    max-width: 600px; /* Maximum width of the content area to keep it readable */
    margin: 0 auto; /* Automatically margin on both sides to center horizontally */
    padding: 20px;
    background-color: #fff; /* Consistent background color */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
    display: flex; /* Using flexbox to align children */
    flex-direction: column; /* Stacking children vertically */
    align-items: center;
}

.booking-page h2, .booking-page p {
    margin: 10px 0; /* Consistent spacing */
    color: #333; /* Text color */
}

.booking-page .button {
    background: #EF6191; /* Signature color */
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px; /* Add space between buttons */
}

.booking-page .button:hover {
    background: #c45577; /* Darker red on hover */
}

.booking-page .details {
    background: #f9f9f9; /* Light grey background for detail sections */
    border: 1px solid #ddd; /* Light grey border */
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 20px; /* Space between detail sections */
}

.booking-page .details label {
    font-weight: bold;
    margin-right: 10px; /* Space between label and content */
}

.booking-page input, .booking-page select, .booking-page textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box; /* Ensures padding does not affect width */
    margin-top: 5px; /* Space above input fields */
}