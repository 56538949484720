/* Base styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
}

/* Container styles */
.venue-booking-container {
    display: flex;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

/* Sidebar styles */
.venue-sidebar {
    width: 10%;
    border-right: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 20px;

}

.venue-sidebar h6 {
    margin-bottom: 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #333;
}

.venue-sidebar .MuiListItem-root {
    border-radius: 8px;
    margin-bottom: 5px;
}

.venue-sidebar .MuiListItem-root:hover {
    background-color: #f0f0f0;
}

/* Main content styles */
.venue-main-content {
    width: 90%;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
}

/* Booking list styles */
.booking-list {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
}

.booking-list-item {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 15px;
    transition: background-color 0.3s;
}

.booking-list-item:hover {
    background-color: #f0f0f0;
}

/* Form styles */
.venue-booking-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.venue-booking-form .MuiFormControl-root {
    margin-bottom: 20px;
}

.venue-booking-form .MuiInputLabel-root {
    color: #333;
}

.venue-booking-form .MuiOutlinedInput-root {
    border-radius: 8px;
}

.venue-booking-form .MuiOutlinedInput-notchedOutline {
    border-color: #ddd;
}

.venue-booking-form .MuiButton-root {
    background-color: #EF6191;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 10px 20px;
    transition: background-color 0.3s;
}

.venue-booking-form .MuiButton-root:hover {
    background-color: #c45577;
}

/* Custom button styles */
.custom-button {
    background-color: #EF6191;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.custom-button:hover {
    background-color: #c45577;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .venue-booking-container {
        flex-direction: column;
    }

    .venue-sidebar,
    .venue-main-content {
        width: 100%;
    }
}
.live-booking-card {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 10px;
    overflow: hidden;
}

.event-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.event-details {
    padding: 20px;
}

.event-title {
    margin-bottom: 15px;
    color: #333;
}

.event-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.info-item {
    display: flex;
    align-items: center;
    color: #666;
}

.info-item .MuiSvgIcon-root {
    margin-right: 5px;
}

.event-description {
    color: #333;
    line-height: 1.6;
}

.custom-button {
    background: #EF6191;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.custom-button:hover {
    background: #c45577;
}
.live-booking-card {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 10px;
    overflow: hidden;
}

.event-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.event-details {
    padding: 20px;
}

.event-title {
    margin-bottom: 15px;
    color: #333;
}

.event-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.info-item {
    display: flex;
    align-items: center;
    color: #666;
}

.info-item .MuiSvgIcon-root {
    margin-right: 5px;
}

.event-description {
    color: #333;
    line-height: 1.6;
}

.custom-button {
    background: #EF6191;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.custom-button:hover {
    background: #c45577;
}

.booking-list-item {
    transition: all 0.3s ease;
  }
  
  .booking-list-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .MuiChip-root {
    font-weight: bold;
  }
  
  .MuiButton-root {
    text-transform: none;
    font-weight: bold;
  }

  .bookings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.bookings-header h4 {
    color: #924AAE;
}

.bookings-header p {
    color: #555;
}

.search-filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.search-filter-section h6 {
    color: #924AAE;
}

.booking-section {
    margin-bottom: 40px;
}

.booking-section h5 {
    color: #924AAE;
    margin-bottom: 20px;
}

.bookings-list-page {
    background-color: #f9f9f9;
  }
  
  .bookings-header {
    text-align: center;
  }
  
  .booking-section {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .booking-row {
    width: 100%;
  }
  
  @media (max-width: 600px) {
    .booking-row .MuiGrid-item {
      text-align: center;
    }
  }
  .booking-row:last-child {
    border-bottom: none;
  }
  
  .booking-row:hover {
    background-color: #f5f5f5;
  }
  
  .MuiChip-root {
    font-weight: 600;
  }
  
  .MuiIconButton-root {
    padding: 8px;
  }
  
  .MuiTypography-h4 {
    color: #1976d2;
    font-weight: 600;
  }
  
  .MuiTypography-h5 {
    color: #1976d2;
    font-weight: 600;
    margin-bottom: 16px;
  }
  
  .MuiTypography-body2 {
    color: #555;
  }
  
  .booking-row .MuiGrid-item {
    display: flex;
    align-items: center;
  }
  
  .booking-row .MuiChip-root {
    min-width: 90px;
    justify-content: center;
  }
  
  .booking-row .MuiIconButton-root {
    color: #1976d2;
  }
  
  .booking-row .MuiIconButton-root:hover {
    color: #1565c0;
  }
  
  @media (max-width: 600px) {
    .booking-row .MuiGrid-item {
      margin-bottom: 8px;
    }
  
    .booking-row .MuiChip-root {
      margin-top: 8px;
    }
  }