.user-booking {
    width: 100%; 
    max-width: 600px; 
    margin: 0 auto; 
    padding: 20px;
    background-color: #fff; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
    display: flex; 
    flex-direction: column; 
    align-items: center;
}

.user-booking h2, .user-booking p {
    margin: 10px 0; 
    color: #333;
}

.user-booking .edit-button {
    background: #EF6191; 
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.user-booking .edit-button:hover {
    background: #c45577; 
}

.user-booking .details {
    background: #f9f9f9; 
    border: 1px solid #ddd; 
    padding: 15px;
    border-radius: 8px;
}

.user-booking .details label {
    font-weight: bold;
    margin-right: 10px; 
}

.user-booking input, .user-booking textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box; 
    margin-top: 5px; 
}

.user-booking img {
    width: 100%; 
    height: auto; 
    max-width: 300px; 
    border-radius: 10px; 
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); 
    margin-top: 20px;
}

.date-picker-container {
    width: 100%;
    max-width: 400px; /* Adjust max-width to your needs */
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}
.date-entry {
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
}

.date-entry p {
    margin: 5px 0;
}

.remove-date-range {
    background: #EF6191;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-date-range:hover {
    background: #c45577;
}

.date-picker {
    width: 100%;
    border: none;
}