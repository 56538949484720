.rbc-calendar {
    background-color: #ffffff; /* Match your main content background */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
  }
  
  /* Header Styles */
  .rbc-header {
    background-color: #1976d2; /* Primary color from your sidebar */
    color: #fff;
    padding: 10px 0;
    text-align: center;
    border-radius: 8px 8px 0 0;
  }
  
  .rbc-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .rbc-month-view, .rbc-time-view {
    min-width: 600px; /* Adjust this value as needed */
  }

  .rbc-toolbar button {
    background-color: #EF6191; /* Custom button color */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .rbc-toolbar button:hover {
    background-color: #c45577;
  }
  
  /* Day Cell Styles */
  .rbc-day-bg {
    background-color: #f9f9f9; /* Light background for days */
  }
  
  .rbc-day-slot {
    border: 1px solid #ddd;
  }
  
  .rbc-selected {
    background-color: #EF6191 !important; /* Highlight selected slot */
    color: white;
  }
  
  /* Event Styles */
  .rbc-event {
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 0.9rem;
  }
  
  .rbc-event:hover {
    background-color: #1565c0;
  }
  
  /* Dialog Styles */
  .MuiDialog-paper {
    border-radius: 8px;
  }
  
  .MuiDialogTitle-root {
    background-color: #1976d2;
    color: white;
  }
  
  .MuiDialogContent-root {
    padding: 20px;
  }
  
  .MuiDialogActions-root {
    padding: 10px 20px;
  }
  
  .MuiTextField-root {
    margin-bottom: 20px;
  }
  
  .MuiButton-containedPrimary {
    background-color: #EF6191;
    color: white;
  }
  
  .MuiButton-containedPrimary:hover {
    background-color: #c45577;
  }
  
  @media (max-width: 768px) {
  .rbc-calendar {
    min-height: 400px;
  }
  .rbc-month-view, .rbc-time-view {
    min-width: 400px;
  }
}