.booking-page {
    width: 100%; 
    max-width: 800px; 
    margin: 0 auto; 
    padding: 20px;
    background-color: #fff; 
    box-shadow: 0 2px 10px rgba(0,0,0,0.1); 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    border-radius: 12px;
}

.nav-bar {
display: flex;
justify-content: center;
margin-bottom: 20px;
}

.nav-button {
background: #EF6191; 
border: none;
border-radius: 20px;
color: white;
padding: 10px 20px;
font-weight: bold;
cursor: pointer;
transition: background-color 0.3s, transform 0.2s;
margin: 0 5px;
}

.nav-button:hover {
background: #c45577 !important;
transform: scale(1.05);
}

.nav-bar .nav-button.active {
background: #EF6191 !important; 
}

.content {
width: 100%;
}

.date-info {
background: #f9f9f9; 
border: 1px solid #ddd; 
padding: 20px;
border-radius: 12px;
margin-bottom: 20px; 
box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.date-info p {
margin: 10px 0; 
color: #333;
font-size: 16px;
}

.user-info {
margin-top: 10px;
padding: 10px;
background: #ececec; 
border-radius: 12px;
box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
}

.user-info p {
margin: 5px 0;
}

.date-info p svg, .user-info p svg {
margin-right: 8px;
color: #EF6191;
}